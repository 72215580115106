<template>
  <div>
    <h6 class="fw-light">Você precisa de bomba de concreto?</h6>
    <div class="d-flex justify-content-center">
      <div>
        <div class="group">
          <button
            type="button"
            class="btn btn-primary btn-labeled btn-labeled-start rounded-pill m-2"
            @click="setPumping('Convencional')"
          >
            <span class="btn-labeled-icon bg-black bg-opacity-20 rounded-pill">
              <i class="ph ph-x-circle"></i>
            </span>
            Não
          </button>
          <button
            type="button"
            class="btn btn-primary btn-labeled btn-labeled-start rounded-pill m-2"
            @click="setPumping('Bombeado')"
          >
            <span class="btn-labeled-icon bg-black bg-opacity-20 rounded-pill">
              <i class="ph ph-check-circle"></i>
            </span>
            Sim
          </button>
        </div>
        <button @click="goBackToPreviousStep" class="btn btn-link">
          Voltar para etapa anterior
        </button>
        <div>
          <div class="text-start mt-2 p-3">
            <div class="row">
              <div class="col-12 col-md-6" :class="alignText">
                <h6>Concreto convencional ou não-bombeado</h6>
                <p>
                  Pode ser utilizado na maioria das estruturas, não é bombeável
                  e tem grande necessidade de equipamentos para realizar seu
                  adensamento. Em alguns casos é descarregado diretamente na
                  estrutura a ser concretada ou pode ser transportado através de
                  carrinhos e giricas, por exemplo.
                </p>
              </div>
              <div class="col-12 col-md-6">
                <h6>Bombeado</h6>
                <p>
                  A diferença é que ele é mais fluido, para poder ser bombeado.
                  É mais fácil de trabalhar, necessita de menos vibração e
                  dispensa uso de carrinhos e mão de obra específica para
                  transporte. Ele também garante mais rapidez e agilidade e tem
                  melhor acabamento. O concreto bombeado requer um investimento
                  um pouco maior do que o concreto convencional: valor da
                  operação do caminhão bomba é cobrado.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ChoosePumping',

  methods: {
    setPumping(_type) {
      this.$emit('setPumping', _type)
    },
    goBackToPreviousStep() {
      this.$emit('goBackToPreviousStep')
    },
  },
  computed: {
    alignText() {
      return window.innerWidth > 992 ? 'text-end' : 'text-start'
    },
  },
  mounted() {
    this.$emit('setHeightContent')
  },
}
</script>
