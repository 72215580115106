<template>
  <div class="d-print-none">
    <div
      class="fab-menu fab-menu-fixed fab-menu-bottom reached-bottom"
      :class="windowSize < 992 ? 'fab-menu-mobile' : 'fab-menu-desktop'"
    >
      <button
        @click="openWhatsApp()"
        type="button"
        class="btn btn-success btn-labeled btn-labeled-start rounded-pill"
      >
        <span class="btn-labeled-icon bg-black bg-opacity-20 rounded-pill"
          ><i class="ph ph-whatsapp-logo"></i
        ></span>
        Falar com especialista
      </button>
    </div>
  </div>
</template>
<style scoped>
.fab-menu-desktop {
  bottom: 126px;
  right: 16px;
}
.fab-menu-mobile {
  bottom: 66px;
  right: 16px;
}
</style>
<script>
export default {
  name: 'Fab',
  components: {},
  data() {
    return {
      windowSize: window.innerWidth,
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      // Atualiza a largura da tela
      this.windowSize = window.innerWidth
    },
    openWhatsApp() {
      window.open(
        'https://api.whatsapp.com/send?phone=5535998416836&text=Olá, estou fazendo meu orçamento online. Gostaria de receber mais informações.',
      )
    },
  },
}
</script>
