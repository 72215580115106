const BASE_URL = '/'
const WELCOME = BASE_URL
const HOME = `${BASE_URL}home`
const BUDGET_DETAILS = `${BASE_URL}budgets/:uuid`
const UNAUTHORIZED = `${BASE_URL}not-authorized`

export default {
  BASE_URL,
  WELCOME,
  HOME,
  BUDGET_DETAILS,
  UNAUTHORIZED,
}
