<template>
  <div>
    <h6 class="fw-light mt-2">Selecione o Slump</h6>
    <div class="d-flex justify-content-center flex-wrap">
      <button
        v-for="(item, index) in ordenedOptions"
        :key="index"
        type="button"
        :disabled="isDisabled(item)"
        :class="currentSlump == item ? 'btn-primary' : 'btn-flat-primary'"
        class="btn mb-2 py-2 mx-2"
        @click="setSlump(item)"
      >
        {{ item }}
      </button>
    </div>
    <div>
      <p class="fs-sm mt-3">
        <em>
          O "slump" do concreto se refere à medida da consistência ou da fluidez
          do concreto fresco. Slump 160 mm a 220 mm: Indica um concreto fluído,
          com boa capacidade de fluxo. É usado em situações onde é necessário um
          concreto que possa preencher formas complexas ou ser facilmente
          bombeado. Slump 140 mm a 160 mm: Indica um concreto com uma
          consistência moderada, adequada para muitos tipos de construção. Slump
          100 mm a 140 mm: Indica um concreto mais rígido, com menos capacidade
          de fluxo. É usado em situações onde é necessária uma alta resistência
          inicial ou para evitar a segregação dos agregados. *A escolha do slump
          adequado depende das necessidades específicas de cada projeto de
          construção.
        </em>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChooseSlump',
  props: {
    products: {
      type: Array,
      required: true,
    },
    currentSlump: {
      type: Number,
      required: true,
    },
    allOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      slump: null,
      slumps: [],
      ordenedOptions: [],
    }
  },
  methods: {
    setSlump(_slump) {
      this.slump = _slump
      this.$emit('setSlump', _slump)
    },
    isDisabled(_slump) {
      const index = this.slumps.indexOf(_slump)
      if (index === -1) {
        return true
      }
      return false
    },
  },
  mounted() {
    this.allOptions.forEach((item) => {
      return this.ordenedOptions.push(item)
    })
    this.products.reduce((acc, obj) => {
      if (!this.slumps.includes(obj.slump)) {
        return this.slumps.push(obj.slump)
      }
      return null
    }, [])
    this.slumps.sort()
    this.ordenedOptions.sort()
  },
}
</script>
