<template>
  <div>
    <h6 class="fw-light">
      Agora, precisamos saber qual estrutura você vai concretar:
    </h6>
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-center multiselect-custom">
          <multiselect
            v-model="selectedPurpose"
            :options="purposes"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :allowEmpty="true"
            :max-height="180"
            placeholder="Selecione uma estrutura"
            selectLabel="Clique para selecionar"
            deselectLabel="Clique para remover a seleção"
            selectedLabel="Selecionado"
          >
          </multiselect>
        </div>
      </div>
    </div>

    <p class="mt-3">
      <button
        type="button"
        class="btn btn-primary btn-labeled btn-labeled-start rounded-pill"
        :disabled="!selectedPurpose"
        @click="setPurpose"
      >
        <span class="btn-labeled-icon bg-black bg-opacity-20 rounded-pill">
          <i class="ph ph-hammer"></i>
        </span>
        Selecionar Finalidade
      </button>
    </p>

    <button @click="goBackToPreviousStep" class="btn btn-link mt-3">
      Voltar para etapa anterior
    </button>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

export default {
  name: 'ChoosePurpose',
  components: { Multiselect },
  props: {
    purposes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedPurpose: null,
    }
  },
  methods: {
    handleImageError() {
      this.imgError = true // Define a imagemErro como verdadeira para ocultar a imagem
    },
    setPurpose() {
      this.$emit('setPurpose', this.selectedPurpose)
    },
    goBackToPreviousStep() {
      this.$emit('goBackToPreviousStep')
    },
  },
  watch: {
    selectedPurpose: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.imgError = false
          this.$emit('setHeightContent')
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$emit('setHeightContent')
  },
}
</script>
