<template>
  <div>
    <h6 class="fw-light">Você já sabe quantos m³ de concreto voce precisa?</h6>
    <div class="d-flex justify-content-center">
      <div>
        <button
          type="button"
          class="btn btn-primary flex-column btn-select"
          @click="setMeasureType('Calculator')"
        >
          <i class="ph ph-calculator ph-2x mb-1"></i>
          Calculadora
          <p class="fw-light mb-0">
            <small> Ajudamos você a calcular quando precisa</small>
          </p>
        </button>
        <button
          type="button"
          class="btn btn-primary flex-column btn-select"
          @click="setMeasureType('Direct')"
        >
          <i class="ph ph-pencil-simple ph-2x mb-1"></i>
          Informar
          <p class="fw-light mb-0">
            <small> Já sei o volume que preciso</small>
          </p>
        </button>
      </div>
    </div>
    <button @click="goBackToPreviousStep" class="btn btn-link">
      Voltar para etapa anterior
    </button>
  </div>
</template>
<style scoped>
.btn-select {
  width: 300px;
  margin: 0.5rem;
}
</style>
<script>
export default {
  name: 'ChooseMeasureType',

  methods: {
    setMeasureType(_type) {
      this.$emit('setMeasureType', _type)
    },
    goBackToPreviousStep() {
      this.$emit('goBackToPreviousStep')
    },
  },
  mounted() {
    this.$emit('setHeightContent')
  },
}
</script>
