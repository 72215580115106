<template>
  <div
    ref="contenthome"
    class="d-flex h-100 justify-content-center"
    :class="showMode"
  >
    <div ref="contentapp" class="flex-fill text-center pb-5">
      <LoadingData v-if="isLoadingProducts" />
      <div v-else>
        <ChooseCity v-if="step === 'ChooseCity'" @setCity="setCity" />

        <ChoosePurpose
          v-if="step === 'ChoosePurpose'"
          :purposes="purposes"
          @setPurpose="setPurpose"
          @goBackToPreviousStep="goBackToPreviousStep"
          @setHeightContent="setHeightContent"
        />

        <ChooseMeasureType
          v-if="step === 'ChooseMeasureType'"
          @setMeasureType="setMeasureType"
          @goBackToPreviousStep="goBackToPreviousStep"
          @setHeightContent="setHeightContent"
        />

        <DirectVolume
          v-if="step === 'DirectVolume'"
          @setVolume="setVolume"
          @goBackToPreviousStep="goBackToPreviousStep"
          @setHeightContent="setHeightContent"
        />

        <CalculateVolume
          v-if="step === 'CalculateVolume'"
          :purpose="purpose"
          @setVolume="setVolume"
          @goBackToPreviousStep="goBackToPreviousStep"
          @setHeightContent="setHeightContent"
        />

        <ChoosePumping
          v-if="step === 'ChoosePumping'"
          @setPumping="setPumping"
          @goBackToPreviousStep="goBackToPreviousStep"
          @setHeightContent="setHeightContent"
        />

        <ShowSugestion
          v-if="step === 'ShowSugestion'"
          :products="products"
          :purpose="purpose"
          :pumping="pumping"
          @goBackToPreviousStep="goBackToPreviousStep"
          @setLeadAndProduct="setLeadAndProduct"
          @setHeightContent="setHeightContent"
        />

        <ShowBudget
          v-if="step === 'ShowBudget'"
          :city="city"
          :product="finalProduct"
          :purpose="purpose"
          :pumping="pumping"
          :volume="volume"
          :lead="lead"
          @setHeightContent="setHeightContent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ChooseCity from '@/components/home/ChooseCity.vue'
import ChoosePurpose from '@/components/home/ChoosePurpose.vue'
import ChooseMeasureType from '@/components/home/ChooseMeasureType.vue'
import DirectVolume from '@/components/home/DirectVolume.vue'
import CalculateVolume from '@/components/home/CalculateVolume.vue'
import ChoosePumping from '@/components/home/ChoosePumping.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import ShowSugestion from '@/components/home/ShowSugestion.vue'
import ShowBudget from '@/components/home/ShowBudget.vue'
import PATHS from '@/router/routesMap'

export default {
  name: 'Home',
  data() {
    return {
      isLoadingProducts: true,
      step: 'ChooseCity',
      stepMap: [
        {
          order: 2,
          name: 'ChooseCity',
        },
        {
          order: 3,
          name: 'ChoosePurpose',
        },
        {
          order: 4,
          name: 'ChooseMeasureType',
        },
        {
          order: 4.1,
          name: 'CalculateVolume',
        },
        {
          order: 4.2,
          name: 'DirectVolume',
        },
        {
          order: 5,
          name: 'ChoosePumping',
        },
        {
          order: 6,
          name: 'ShowSugestion',
        },
        {
          order: 7,
          name: 'ShowBudget',
        },
      ],
      lead: null,
      city: null,
      previousStep: 'DoYouKnowTypeDoYouNeed',
      typeDoYouNeed: null,
      numberOfFloors: null,
      measureType: null,
      purpose: null,
      pumping: null,
      volume: 0,
      products: [],
      purposes: [],
      filteredProducts: [],
      finalProduct: {},
      windowHeight: window.innerHeight,
      contentApp: 0,
      contentHome: 0,
      showMode: 'align-items-center',
    }
  },
  methods: {
    setHeightContent() {
      this.contentApp = this.$refs.contentapp.clientHeight
      this.contentHome = this.$refs.contenthome.clientHeight
      if (this.contentApp > this.contentHome) {
        this.showMode = 'align-items-start'
      } else {
        this.showMode = 'align-items-center'
      }
    },
    goBackToPreviousStep() {
      const step = this.stepMap.find((_step) => _step.name === this.step)
      const order = Math.floor(step.order) - 1
      const destinyStep = this.stepMap.find((_step) => _step.order === order)
      this.step = destinyStep.name
    },
    setCity(city) {
      this.city = city
      this.step = 'ChoosePurpose'
    },
    setPumping(pumping) {
      this.pumping = pumping
      this.step = 'ShowSugestion'
    },
    setPurpose(_type) {
      this.purpose = _type
      this.filteredProducts = this.products.filter((item) => {
        return item.purposes === _type
      })
      this.step = 'ChooseMeasureType'
    },
    setVolume(_volume) {
      this.volume = _volume.volume
      this.step = 'ChoosePumping'
    },
    async setLeadAndProduct(item) {
      this.finalProduct = item.product
      this.lead = item.lead
      try {
        const response = await this.$store.dispatch('submitBudget', {
          lead: this.lead,
          city: this.city,
          typeDoYouNeed: this.typeDoYouNeed,
          noHasSugestion: this.noHasSugestion,
          measureType: this.measureType,
          purpose: this.purpose,
          pumping: this.pumping,
          volume: this.volume,
          finalProduct: this.finalProduct,
        })
        this.$router.push({
          name: 'BudgetDetails',
          params: { uuid: response },
        })
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    // Define o se o cliente já sabe o tipo de FCK que ele precisa
    setTypeDoYouNeed(_type) {
      this.typeDoYouNeed = _type
      if (_type === 'Sim') {
        this.step = 'ChoosePurpose'
      } else if (_type === 'Não') {
        this.step = 'ChooseNumberOfFloors'
      }
    },
    // define número de pavimentos
    setNumberOfFloors(_type) {
      this.numberOfFloors = _type
      if (_type === '1 Pavimento') {
        this.step = 'ChoosePurpose'
      } else if (_type === 'Mais de 1 Pavimento') {
        this.step = 'TalkToAnEngineer'
      }
    },
    setMeasureType(_type) {
      this.measureType = _type
      if (_type === 'Calculator') {
        this.step = 'CalculateVolume'
      } else if (_type === 'Direct') {
        this.step = 'DirectVolume'
      }
    },

    setFck(_fck) {
      this.fck = _fck.fck
      this.step = 'ChooseFck'
    },
    async getProducts() {
      try {
        this.isLoadingProducts = true
        this.products = await this.$store.dispatch('getProducts')
        this.products.reduce((acc, obj) => {
          if (!this.purposes.includes(obj.purposes)) {
            return this.purposes.push(obj.purposes)
          }
          return null
        }, [])
        this.isLoadingProducts = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  components: {
    LoadingData,
    ChooseCity,
    ChoosePurpose,
    ChooseMeasureType,
    CalculateVolume,
    DirectVolume,
    ChoosePumping,
    ShowSugestion,
    ShowBudget,
  },
  watch: {
    step: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.previousStep = oldVal
        }
      },
      immediate: true,
    },
  },
  computed: {},
  mounted() {
    this.getProducts()
  },
}
</script>
