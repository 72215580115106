<template>
  <div class="d-print-none">
    <div class="carousel">
      <div class="carousel-container" :style="containerStyle">
        <div
          class="carousel-slide"
          v-for="(image, index) in images"
          :key="index"
          :style="`transform: ${image.transform}`"
        >
          <img :src="image.path" alt="Imagem" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fab-menu-desktop {
  bottom: 126px;
  right: 16px;
}

.fab-menu-mobile {
  bottom: 66px;
  right: 16px;
}

.carousel {
  text-align: center;
}

.carousel-container {
  display: flex;
  /* overflow: hidden; */
  width: 100%;
  margin: 0 auto;
  transition: transform 0.3s ease-in-out;
}

.carousel-slide {
  flex: 0 0 auto;
  width: 100%;
}
</style>

<script>
export default {
  name: 'Banner',
  components: {},
  data() {
    return {
      windowSize: window.innerWidth,
      images: [
        { url: 'tranquilo', path: '', transform: `translateX(0%)` },
        { url: '10x', path: '', transform: `translateX(0%)` },
        { url: 'vaidesn', path: '', transform: `translateX(000%)` },
      ],
      currentIndex: 0,
      intervalId: 0,
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  computed: {
    containerStyle() {
      return {
        transform: `translateX(-${this.currentIndex * 100}%)`,
      }
    },
    sizeImg() {
      if (this.windowSize <= 720) {
        return 'sm'
      }
      if (this.windowSize <= 1250) {
        return 'md'
      }
      return 'lg'
    },
  },
  methods: {
    getImagePath(imageName) {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/img/banner/${imageName}_${this.sizeImg}.jpg`)
    },
    handleResize() {
      // Atualiza a largura da tela
      this.windowSize = window.innerWidth

      // Atualiza o caminho da imagem com base no novo tamanho da tela
      this.images.forEach((image) => {
        // eslint-disable-next-line no-param-reassign
        image.path = this.getImagePath(image.url)
      })
    },
    slideStyle(index) {
      return {
        transform: `translateX(${index * 100 - 40}%)`,
      }
    },
    startAutoPlay() {
      // Inicia o temporizador para passar automaticamente as imagens
      this.intervalId = setInterval(() => {
        this.nextSlide()
      }, 5000) // Intervalo de 3 segundos (ajuste conforme necessário)
    },
    nextSlide() {
      if (this.currentIndex < this.images.length - 1) {
        // eslint-disable-next-line no-plusplus
        this.currentIndex++
      } else {
        this.currentIndex = 0
      }
    },
  },
  created() {
    this.startAutoPlay() // Inicia o autoplay quando o componente é criado
  },
  beforeUnmount() {
    // Limpa o temporizador quando o componente é destruído
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
}
</script>
