<template>
  <div class="container mt-3">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <img src="@/assets/img/logos/color.png" class="logo-budget" />
          </div>
          <div class="col-3"><h6 class="fw-bold mt-2">ORÇAMENTO</h6></div>
          <div class="col-3">DATA: {{ onlyDateNow() }}</div>
        </div>
        <div class="row text-start" v-if="budgetData">
          <div class="col-12">
            <p>Nome: {{ budgetData.name }}</p>
          </div>
          <div class="col-12">
            <p>WhatsApp: {{ budgetData.whatsApp }}</p>
          </div>
          <div class="col-12">
            <p>Cidade: {{ budgetData.city }}</p>
          </div>
          <div class="col-12">
            <p>Finalidade: {{ budgetData.productPurpose }}</p>
          </div>
          <div class="col-12">
            <p>
              Produto: FCK {{ budgetData.productFck }} | Brita
              {{ budgetData.productGravel }} | Slump >=
              {{ budgetData.productSlump }} mm
            </p>
          </div>
          <div class="col-12">
            <p>Volume: {{ budgetData.productAmount }}m³</p>
          </div>
          <div class="col-12">
            <p>
              Valor Unitário: {{ moneyToCurrencyBr(budgetData.productPrice) }}
            </p>
          </div>
          <div class="col-12">
            <p>
              Total Produto:
              {{ moneyToCurrencyBr(budgetData.productPriceTotal) }}
            </p>
          </div>
          <div class="col-12" v-if="budgetData.productPumping === 'Bombeado'">
            <p>Bombeamento: {{ moneyToCurrencyBr(budgetData.pumpingValue) }}</p>
          </div>
          <div class="col-12" v-if="budgetData.productPumping === 'Bombeado'">
            <p>
              Total do Orçamento:
              {{ moneyToCurrencyBr(budgetData.productPriceBudget) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <h6 class="text-primary">
      Esse é um orçamento automático e preliminar! Para negociar, entender os
      valores e sanar qualquer dúvida, fale com nossos vendedores.
    </h6>
  </div>
</template>
<style scoped>
.logo-budget {
  width: 150px;
}
</style>
<script>
import { onlyDateNow } from '@/utils/date'
import { moneyToCurrencyBr } from '@/utils/strings'

export default {
  name: 'ShowBudget',
  props: {
    product: {
      type: Array,
      required: true,
    },
    city: {
      type: Object,
      required: true,
    },
    volume: {
      type: Number,
      required: true,
    },
    lead: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      budgetData: null,
      onlyDateNow,
      moneyToCurrencyBr,
      defaultColspan: 3,
    }
  },
  methods: {
    async save() {
      try {
        await this.$store.dispatch('submitBudget', this.budgetData)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    const price =
      this.volume >= 30
        ? Number(this.product.priceWithDiscount) +
          Number(this.city.valueIncrease)
        : Number(this.product.price) + Number(this.city.valueIncrease)
    const pumpingValue = this.volume < 10 ? 400 : 400 + (this.volume - 10) * 30
    this.budgetData = {
      name: this.lead.name,
      whatsApp: this.lead.whatsApp,
      city: this.city.name,
      cityIncrease: this.city.valueIncrease,
      productPurpose: this.product.purposes,
      productCode: this.product.productCode,
      productFck: this.product.fck,
      productPumping: this.product.pumping,
      productSlump: this.product.slump,
      productGravel: this.product.gravel,
      productPrice: price,
      productPriceTotal: this.volume * price,
      productAmount: this.volume,
      productOriginalPrice: this.product.price,
      productOriginalPriceWithDiscount: this.product.priceWithDiscount,
      pumpingValue: this.product.pumping === 'Bombeado' ? pumpingValue : 0,
      productPriceBudget: this.volume * price + pumpingValue,
    }
    this.save()
  },
}
</script>
