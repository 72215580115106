<template>
  <div>
    <h4 class="fw-light">Qual é o volume de concreto?</h4>
    <h6 class="fw-light">Informe o volume em m³</h6>
    <form @submit.prevent="save">
      <div class="d-block">
        <div class="form-control-feedback form-control-feedback-start mb-3">
          <input
            type="number"
            step="0.5"
            class="form-control form-control-lg text-center"
            :class="{
              'is-invalid': v$.volume.$errors.length,
            }"
            v-model="v$.volume.$model"
          />
          <div class="form-control-feedback-icon">
            <i class="ph ph-cube"></i>
          </div>
          <span v-if="v$.volume.required.$invalid" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="v$.volume.minValue.$invalid" class="invalid-feedback">
            Volume mínimo é 3m³
          </span>
          <span v-if="v$.volume.maxValue.$invalid" class="invalid-feedback">
            Volume máximo é 200m³
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-center pt-2">
        <button
          type="submit"
          class="btn btn-primary btn-labeled btn-labeled-start rounded-pill"
          :disabled="v$.$invalid"
        >
          <span class="btn-labeled-icon bg-black bg-opacity-20 rounded-pill"
            ><i class="ph ph-ruler"></i
          ></span>
          Prosseguir
        </button>
      </div>
      <button @click="goBackToPreviousStep" class="btn btn-link" type="button">
        Voltar para etapa anterior
      </button>
    </form>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minValue, maxValue } from '@vuelidate/validators'

export default {
  name: 'DirectVolume',
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      volume: '',
    }
  },
  methods: {
    async save() {
      if (!this.v$.$invalid) {
        try {
          this.$emit('setVolume', { volume: this.volume })
        } catch (error) {
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
    goBackToPreviousStep() {
      this.$emit('goBackToPreviousStep')
    },
  },
  validations() {
    return {
      volume: { required, minValue: minValue(3), maxValue: maxValue(200) },
    }
  },
  mounted() {
    this.$emit('setHeightContent')
  },
}
</script>
