<template>
  <div>
    <h6 class="fw-light">Informe as medições:</h6>
    <form @submit.prevent="save">
      <div v-if="!imgError">
        <img
          :src="`${imgPath}${removeSpecialCharacters(purpose)}.png`"
          height="350"
          @error="handleImageError"
        />
      </div>
      <div class="row">
        <div class="col">
          <label for="width">{{ nameInput }} (m):</label>
          <div class="form-control-feedback form-control-feedback-start mb-3">
            <input
              id="width"
              type="number"
              step="0.01"
              class="form-control form-control-lg text-center"
              :class="{
                'is-invalid': v$.width.$errors.length,
              }"
              v-model="v$.width.$model"
            />
            <div class="form-control-feedback-icon">
              <i class="ph ph-arrows-horizontal"></i>
            </div>

            <span v-if="v$.width.required.$invalid" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span v-if="v$.width.minValue.$invalid" class="invalid-feedback">
              Volume mínimo é 0.01 m
            </span>
            <span v-if="v$.width.maxValue.$invalid" class="invalid-feedback">
              Volume máximo é 200 m
            </span>
          </div>
        </div>
        <div class="col">
          <label for="height">{{ inputHeightOrThickness }}:</label>
          <div class="form-control-feedback form-control-feedback-start mb-3">
            <input
              id="height"
              type="number"
              step="0.1"
              class="form-control form-control-lg text-center"
              :class="{
                'is-invalid': v$.height.$errors.length,
              }"
              v-model="v$.height.$model"
            />
            <div class="form-control-feedback-icon">
              <i class="ph ph-arrows-vertical"></i>
            </div>

            <span v-if="v$.height.required.$invalid" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span v-if="v$.height.minValue.$invalid" class="invalid-feedback">
              Volume mínimo é 0.01m
            </span>
            <span v-if="v$.height.maxValue.$invalid" class="invalid-feedback">
              Volume máximo é 200m
            </span>
          </div>
        </div>
        <div class="col" v-show="showInput">
          <label for="length">Comprimento (m):</label>
          <div class="form-control-feedback form-control-feedback-start mb-3">
            <input
              id="length"
              type="number"
              step="0.01"
              class="form-control form-control-lg text-center"
              :class="{
                'is-invalid': v$.length.$errors.length,
              }"
              v-model="v$.length.$model"
            />
            <div class="form-control-feedback-icon">
              <i class="ph ph-arrows-out-simple"></i>
            </div>

            <span
              v-if="v$.length.requiredIfInput.$invalid"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
            <span v-if="v$.length.minValue.$invalid" class="invalid-feedback">
              Volume mínimo é 0,01m
            </span>
            <span v-if="v$.length.maxValue.$invalid" class="invalid-feedback">
              Volume máximo é 200m
            </span>
          </div>
        </div>
        <div
          class="col-12 col"
          v-show="showQtd"
          :class="showQtd ? 'col-md-3' : 'col-md-4'"
        >
          <label for="quantity">Quantidade:</label>
          <div class="form-control-feedback form-control-feedback-start mb-3">
            <input
              id="quantity"
              type="number"
              step="0.01"
              class="form-control form-control-lg text-center"
              :class="{
                'is-invalid': v$.quantity.$errors.length,
              }"
              v-model="v$.quantity.$model"
            />
            <div class="form-control-feedback-icon">
              <i class="ph ph-x"></i>
            </div>

            <span v-if="v$.quantity.required.$invalid" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span v-if="v$.quantity.minValue.$invalid" class="invalid-feedback">
              Quantidade mínima é 1
            </span>
            <span v-if="v$.quantity.maxValue.$invalid" class="invalid-feedback">
              Quantidade mínima é 100
            </span>
          </div>
        </div>
      </div>
      <div class="row" v-if="volume > 0">
        <div class="col mt-3">
          <p>
            Serão necessários <strong>{{ volume }}m³</strong> de concreto.
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-center pt-2">
        <button
          type="submit"
          class="btn btn-primary btn-labeled btn-labeled-start rounded-pill"
          :disabled="v$.$invalid || volume < 1"
        >
          <span class="btn-labeled-icon bg-black bg-opacity-20 rounded-pill"
            ><i class="ph ph-ruler"></i
          ></span>
          Prosseguir
        </button>
      </div>
      <button @click="goBackToPreviousStep" class="btn btn-link" type="button">
        Voltar para etapa anterior
      </button>
    </form>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { requiredIf, required, minValue, maxValue } from '@vuelidate/validators'
import { removeSpecialCharacters } from '@/utils/strings'

export default {
  name: 'CalculateVolume',
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      circularNames: ['Tubulão', 'Estaca hélice'],
      purposeWithOutQtd: [
        'Laje',
        'Sarjeta',
        'Meio-fio/guia',
        'Contrapiso',
        'Calçada',
        'Piso',
      ],
      heightNames: [
        'Pilar',
        'Viga',
        'Sarjeta',
        'Meio-fio/guia',
        'Tubulão',
        'Estaca hélice',
      ],
      volume: '',
      width: '',
      height: '',
      length: '',
      quantity: 1,
      imgError: false,
      imgPath: `${process.env.VUE_APP_BASE_URL_API}/assets/purposes/`,
      removeSpecialCharacters,
    }
  },
  props: {
    purpose: {
      type: String,
      required: true,
    },
  },
  watch: {
    width: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.calculateVolume()
        }
      },
      immediate: true,
    },
    height: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.calculateVolume()
        }
      },
      immediate: true,
    },
    length: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.calculateVolume()
        }
      },
      immediate: true,
    },
    quantity: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.calculateVolume()
        }
      },
      immediate: true,
    },
  },
  methods: {
    calculateVolume() {
      if (this.circularNames.includes(this.purpose)) {
        this.volume = parseFloat(
          (
            ((((3.1415 * (Number(this.width) * Number(this.width))) / 4) *
              Number(this.height)) /
              100) *
            Number(this.quantity)
          ).toFixed(2),
        )
      } else {
        this.volume =
          ((Number(this.width) * Number(this.height)) / 100) *
          Number(this.length) *
          Number(this.quantity)
      }
    },
    async save() {
      if (!this.v$.$invalid) {
        try {
          this.$emit('setVolume', { volume: this.volume })
        } catch (error) {
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
    setMeasureType(_type) {
      this.$emit('setMeasureType', _type)
    },
    goBackToPreviousStep() {
      this.$emit('goBackToPreviousStep')
    },
  },
  computed: {
    // a computed getter
    nameInput() {
      return !this.circularNames.includes(this.purpose) ? 'Largura' : 'Diametro'
    },
    inputHeightOrThickness() {
      return this.heightNames.includes(this.purpose)
        ? 'Altura (cm)'
        : 'Espessura (cm)'
    },
    showInput() {
      return !this.circularNames.includes(this.purpose)
    },
    showQtd() {
      return !this.purposeWithOutQtd.includes(this.purpose)
    },
  },
  validations() {
    return {
      width: { required, minValue: minValue(0.01), maxValue: maxValue(200) },
      length: {
        requiredIfInput: requiredIf(this.showInput),
        minValue: minValue(0.01),
        maxValue: maxValue(200),
      },
      height: { required, minValue: minValue(0.01), maxValue: maxValue(200) },
      quantity: { required, minValue: minValue(1), maxValue: maxValue(100) },
    }
  },
  mounted() {
    this.$emit('setHeightContent')
  },
}
</script>
