<template>
  <div>
    <h6 class="fw-light mt-2">Selecione a Brita</h6>
    <div class="d-flex justify-content-center flex-wrap">
      <button
        v-for="(item, index) in ordenedOptions"
        :key="index"
        type="button"
        :disabled="isDisabled(item)"
        :class="currentGravel == item ? 'btn-primary' : 'btn-flat-primary'"
        class="btn py-2 mx-2"
        @click="setGravel(item)"
      >
        {{ item }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChooseGravel',
  props: {
    products: {
      type: Array,
      required: true,
    },
    currentGravel: {
      type: Number,
      required: true,
    },
    allOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      gravel: null,
      gravels: [],
      ordenedOptions: [],
    }
  },
  methods: {
    setGravel(_gravel) {
      this.gravel = _gravel
      this.$emit('setGravel', _gravel)
    },
    isDisabled(_gravel) {
      const index = this.gravels.indexOf(_gravel)
      if (index === -1) {
        return true
      }
      return false
    },
  },
  mounted() {
    this.allOptions.forEach((item) => {
      return this.ordenedOptions.push(item)
    })
    this.products.reduce((acc, obj) => {
      if (!this.gravels.includes(obj.gravel)) {
        return this.gravels.push(obj.gravel)
      }
      return null
    }, [])
    this.gravels.sort()
    this.ordenedOptions.sort()
  },
}
</script>
