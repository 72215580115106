<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <div v-if="productSugestion" class="p-2">
      <h6 class="fw-light">Esse é o traço sugerido para seu concreto</h6>
      <div>
        <div
          class="alert bg-primary text-white alert-icon-start alert-dismissible fade show border-0"
        >
          <span class="alert-icon bg-black bg-opacity-20">
            <i class="ph ph-check"></i>
          </span>
          FCK {{ productSugestion.fck }} | Brita {{ productSugestion.gravel }} |
          Slump >= {{ productSugestion.slump }} mm
        </div>

        <p>
          <strong class="text-danger">Atenção!</strong> o traço deve ser
          validado pelo engenheiro responsável.
        </p>
        <button
          v-if="!showMoreOptionsComponents"
          @click="showMoreOptions"
          class="btn btn-link"
          type="button"
        >
          <h6>Tem outra especificação?</h6>
        </button>
        <div v-else class="row mb-2">
          <ChooseFck
            v-if="possibleProducts.length >= 1"
            class="col-12"
            :products="possibleProducts"
            :allOptions="allOptions.fck"
            :currentFck="productSugestion.fck"
            @setFck="setFck"
          />
          <ChooseSlump
            class="col-12"
            v-if="moreOptionsFck && possibleProducts.length >= 1"
            :products="possibleProducts"
            :allOptions="allOptions.slump"
            :currentSlump="productSugestion.slump"
            @setSlump="setSlump"
          />
          <ChooseGravel
            class="col-12"
            v-if="moreOptionsSlump && possibleProducts.length >= 1"
            :products="possibleProducts"
            :allOptions="allOptions.gravel"
            :currentGravel="productSugestion.gravel"
            @setGravel="setGravel"
          />
        </div>
        <h6>
          Se optar por orçar seu concreto com essa especificação, nos informe
          agora seu nome e Whatsapp, por favor:
        </h6>
      </div>
    </div>
    <div v-if="noHasSugestion">
      <h5><strong class="text-danger">Atenção!</strong></h5>
      <p>
        No momento não temos sugestão de concreto para os dados informados. Você
        pode falar com um especialista clicando no botão abaixo ou preencher
        seus dados que entraremos em contato.
      </p>
    </div>

    <form @submit.prevent="save">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="text-start">
            <label for="name-field">Nome:</label>
            <div class="form-control-feedback form-control-feedback-start mb-3">
              <input
                id="name-field"
                type="text"
                class="form-control form-control-lg"
                placeholder="informe seu nome..."
                :class="{
                  'is-invalid': v$.name.$errors.length,
                }"
                v-model="v$.name.$model"
              />
              <div class="form-control-feedback-icon">
                <i class="ph ph-user"></i>
              </div>
            </div>
            <span v-if="v$.name.required.$invalid" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span v-if="v$.name.minLength.$invalid" class="invalid-feedback">
              Preencha seu nome completo
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="text-start">
            <label for="whatsapp-field">WhatsApp:</label>
            <div class="form-control-feedback form-control-feedback-start mb-3">
              <input
                id="whatsapp-field"
                type="text"
                class="form-control form-control-lg"
                placeholder="Informe seu WhatsApp..."
                :class="{
                  'is-invalid': v$.whatsApp.$errors.length,
                }"
                v-model="v$.whatsApp.$model"
                v-maska="['(##) ####-####', '(##) #####-####']"
              />
              <div class="form-control-feedback-icon">
                <i class="ph ph-whatsapp-logo"></i>
              </div>
            </div>
            <span v-if="v$.whatsApp.required.$invalid" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span
              v-if="v$.whatsApp.minLength.$invalid"
              class="invalid-feedback"
            >
              Preencha o numero de telefone
            </span>
            <span
              v-if="v$.whatsApp.maxLength.$invalid"
              class="invalid-feedback"
            >
              Preencha o numero de telefone
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center pt-2">
        <button
          type="submit"
          class="btn btn-primary btn-labeled btn-labeled-start rounded-pill"
          :disabled="v$.$invalid || isSaving"
        >
          <span class="btn-labeled-icon bg-black bg-opacity-20 rounded-pill">
            <i class="ph ph-currency-circle-dollar"></i>
          </span>
          Seguir Para o Orçamento
        </button>
      </div>
    </form>
    <button @click="goBackToPreviousStep" class="btn btn-link" type="button">
      Voltar para etapa anterior
    </button>
  </div>
</template>

<script>
import { maska } from 'maska'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import ChooseFck from '@/components/home/ChooseFck.vue'
import ChooseGravel from '@/components/home/ChooseGravel.vue'
import ChooseSlump from '@/components/home/ChooseSlump.vue'

export default {
  name: 'ShowSugestion',
  setup() {
    return { v$: useVuelidate() }
  },
  directives: { maska },
  props: {
    products: {
      type: Array,
      required: true,
    },
    pumping: {
      type: String,
      required: true,
    },
    purpose: {
      type: String,
      required: true,
    },
  },
  components: { ChooseFck, ChooseGravel, ChooseSlump },
  data() {
    return {
      isSaving: false,
      name: '',
      whatsApp: '',
      allOptions: {
        fck: [],
        gravel: [],
        slump: [],
      },
      noHasSugestion: false,
      productSugestion: null,
      possibleProducts: [],
      avaliableProducts: [],
      moreOptionsGravel: null,
      moreOptionsFck: null,
      moreOptionsSlump: null,
      showMoreOptionsComponents: true,
    }
  },
  methods: {
    showMoreOptions() {
      this.showMoreOptionsComponents = true
      this.$emit('setHeightContent')
    },
    filterBestSugestion() {
      this.products.forEach((product) => {
        this.allOptions.fck.push(product.fck)
        this.allOptions.gravel.push(product.gravel)
        this.allOptions.slump.push(product.slump)
      })
      this.allOptions.fck = new Set(this.allOptions.fck)
      this.allOptions.gravel = new Set(this.allOptions.gravel)
      this.allOptions.slump = new Set(this.allOptions.slump)

      const options = this.products.filter((product) => {
        return (
          product.purposes === this.purpose && product.pumping === this.pumping
        )
      })
      this.avaliableProducts = options
      this.possibleProducts = options
      this.$emit('setHeightContent')
      this.defineSugestion()
    },

    defineSugestion() {
      this.possibleProducts = this.avaliableProducts
      this.productSugestion = null

      if (this.moreOptionsFck) {
        this.possibleProducts = this.possibleProducts.filter((product) => {
          return product.fck === this.moreOptionsFck
        })
      }
      if (this.moreOptionsSlump) {
        this.possibleProducts = this.possibleProducts.filter((product) => {
          return product.slump === this.moreOptionsSlump
        })
      }
      if (this.moreOptionsGravel) {
        this.possibleProducts = this.possibleProducts.filter((product) => {
          return product.gravel === this.moreOptionsGravel
        })
      }
      if (this.possibleProducts.length === 0) {
        this.noHasSugestion = true
      }
      this.possibleProducts.forEach((item) => {
        if (this.productSugestion === null) {
          this.productSugestion = item
        } else if (
          this.productSugestion &&
          this.productSugestion.fck > item.fck
        ) {
          this.productSugestion = item
        }
      })
      this.$emit('setHeightContent')
    },
    setFck(_fck) {
      this.moreOptionsGravel = null
      this.moreOptionsSlump = null
      this.moreOptionsFck = _fck
      this.defineSugestion()
    },
    setSlump(_slump) {
      this.moreOptionsGravel = null
      this.moreOptionsSlump = _slump
      this.defineSugestion()
    },
    setGravel(_gravel) {
      this.moreOptionsGravel = _gravel
      this.defineSugestion()
    },
    goBackToPreviousStep() {
      this.$emit('goBackToPreviousStep')
    },

    async save() {
      this.isSaving = true
      if (!this.v$.$invalid) {
        try {
          this.$emit('setLeadAndProduct', {
            lead: {
              name: this.name,
              whatsApp: this.whatsApp,
            },
            product: this.productSugestion,
            noHasSugestion: this.noHasSugestion,
          })
        } catch (error) {
          this.isSaving = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },
  validations() {
    return {
      name: {
        required,
        minLength: minLength(2),
      },
      whatsApp: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
    }
  },
  mounted() {
    this.filterBestSugestion()
    setTimeout(() => {
      this.$emit('setHeightContent')
    }, 300)
  },
}
</script>
