import { dateTimeSqlToDateTimeBR } from './date'

const createdUpdatedUser = (userName, date) => {
  return userName !== null && userName !== ''
    ? `${userName} em ${dateTimeSqlToDateTimeBR(date)}`
    : '-'
}

const statusTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 0:
      return '<span class="badge bg-danger">Inativo</span>'
    case 1:
      return '<span class="badge bg-success">Ativo</span>'
    case 2:
      return '<span class="badge bg-primary">Aguardando</span>'
    default:
      return '<span class="badge bg-secondary">Indefinido</span>'
  }
}

const statusProjectTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 0:
      return '<span class="badge bg-danger">Rejeitado</span>'
    case 1:
      return '<span class="badge bg-warning">Em Cadastro</span>'
    default:
      return '<span class="badge bg-secondary">Indefinido</span>'
  }
}

const platformAccessTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 0:
      return '<span class="badge bg-danger">Não</span>'
    case 1:
      return '<span class="badge bg-success">Sim</span>'
    default:
      return '<span class="badge bg-secondary">Indefinido</span>'
  }
}

const moneyToCurrencyBr = (value) => {
  if (value !== null && value !== '') {
    const money = parseFloat(value)
    return money.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }
  return value
}

const removeSpecialCharacters = (str) => {
  // Remove acentos
  const strWithoutAcents = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  // Remove caracteres especiais e espaços
  return strWithoutAcents
    .replace(/[^\w\s]/gi, '')
    .replace(/\s+/g, '')
    .toLowerCase()
}

export {
  createdUpdatedUser,
  statusTranslate,
  statusProjectTranslate,
  platformAccessTranslate,
  moneyToCurrencyBr,
  removeSpecialCharacters,
}
