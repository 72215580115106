<template>
  <div>
    <h6 class="fw-light">Alterar o FCK?</h6>
    <div class="d-flex justify-content-center flex-wrap">
      <button
        v-for="(item, index) in ordenedOptions"
        :key="index"
        type="button"
        :disabled="isDisabled(item)"
        :class="currentFck == item ? 'btn-primary' : 'btn-flat-primary'"
        class="btn py-2 mx-2 mb-2"
        @click="setFck(item)"
      >
        FCK {{ item }}
      </button>
    </div>
    <div>
      <p class="fs-sm mt-3">
        <em>
          "fck" é um termo que se refere à resistência característica à
          compressão do concreto, representa a capacidade do concreto de
          suportar cargas de compressão antes de falhar. O valor específico de
          "fck" varia de acordo com as necessidades do projeto e as normas de
          construção aplicáveis.</em
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChooseFck',
  props: {
    products: {
      type: Array,
      required: true,
    },
    currentFck: {
      type: Number,
      required: true,
    },
    allOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fck: null,
      fcks: [],
      ordenedOptions: [],
    }
  },
  methods: {
    setFck(_fck) {
      this.fck = _fck
      this.$emit('setFck', _fck)
    },
    isDisabled(_fck) {
      const index = this.fcks.indexOf(_fck)
      if (index === -1) {
        return true
      }
      return false
    },
  },
  mounted() {
    this.allOptions.forEach((item) => {
      return this.ordenedOptions.push(item)
    })
    this.products.reduce((acc, obj) => {
      if (!this.fcks.includes(obj.fck)) {
        return this.fcks.push(obj.fck)
      }
      return null
    }, [])
    this.fcks.sort()
    this.ordenedOptions.sort()
  },
}
</script>
