<template>
  <div
    class="navbar navbar-light navbar-static navbar-expand-lg d-print-none"
    z-index="-1"
  >
    <div class="container-fluid">
      <!-- Mobile togglers -->
      <div class="d-flex d-lg-none me-2">
        <button
          type="button"
          class="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-navigation"
          aria-expanded="true"
        >
          <i class="ph ph-list" />
        </button>
      </div>
      <!-- /mobile togglers -->

      <!-- Navbar brand -->
      <div class="d-inline-flex flex-0 flex-lg-0">
        <a
          href="https://gruposn.com.br"
          class="navbar-brand d-inline-flex align-items-center"
        >
          <img src="@/assets/img/logos/color.png" class="logo"
        /></a>
      </div>
      <!-- /navbar brand -->

      <!-- Right content -->
      <div
        id="navbar-navigation"
        class="navbar-collapse collapse flex-row justify-content-end align-items-baseline order-1 order-lg-2"
      >
        <span class="navbar-text d-block d-xl-inline-block me-3 text-primary">
          <h6 class="mb-1"><strong>Onde estamos</strong></h6>
          <p class="mb-0">
            Estrada do Madeira, Km 2.5 <br />Lavras/MG - 37200-000
          </p></span
        >
        <span class="navbar-text d-block d-xl-inline-block me-3 text-primary"
          ><h6 class="mb-1"><strong>Contato</strong></h6>
          <p class="mb-0">
            Tel: (35) 3821-7111 | (35) 3409-7111 <br />
            (35) 99114-1234 | (35) 99968-4860
          </p></span
        >
        <span class="navbar-text d-block d-xl-inline-block text-primary"
          ><h6 class="mb-1"><strong>Nossos termos</strong></h6>
          <p class="mb-0">
            <a
              href="https://gruposn.com.br/politica-de-privacidade/"
              target="_blank"
              >Politica de Privacidade</a
            >
          </p></span
        >
      </div>
      <!-- /right content -->
    </div>
  </div>
</template>
<style scoped>
.logo {
  width: 200px;
}
</style>
<script>
export default {
  name: 'Footer',
  components: {},
  data() {
    return {}
  },
}
</script>
