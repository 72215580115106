import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async submitBudget({ commit }, data) {
      try {
        const response = await api.post(`/budgets`, data)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getBudget({ commit }, data) {
      try {
        const response = await api.get(`/budgets/${data}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
