<template>
  <Banner />
  <div class="page-content fw-light">
    <div class="content-wrapper">
      <div class="content-inner d-flex align-items-center p-2 overflow-auto">
        <slot />
      </div>
    </div>
  </div>
  <Fab />
  <Footer />
</template>

<script>
import Footer from '@/components/commons/Footer.vue'
import Fab from '@/components/commons/Fab.vue'
import Banner from '@/components/commons/Banner.vue'

export default {
  name: 'Layout',

  components: {
    Footer,
    Fab,
    Banner,
  },

  data() {
    return {
      currentRoute: this.$route.name,
    }
  },

  computed: {
    addClass() {
      return this.routesName.includes(this.$route.name)
        ? 'full-screen'
        : 'min-full-screen'
    },
  },
}
</script>
