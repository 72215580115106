<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <h4 class="fw-light">Bem-vindo!</h4>
    <h6 class="fw-light">É hora de orçar seu concreto</h6>
    <LoadingData v-if="isLoadingCities" />
    <div v-else>
      <h6 class="fw-light">Onde é a obra?</h6>
      <div class="d-flex justify-content-center multiselect-custom">
        <multiselect
          v-model="selectedCity"
          :options="cities"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="true"
          :preserve-search="true"
          :allowEmpty="true"
          :max-height="180"
          placeholder="Selecione uma cidade"
          label="name"
          track-by="name"
          selectLabel="Clique para selecionar"
          deselectLabel="Clique para remover a seleção"
          selectedLabel="Selecionado"
        >
          >
        </multiselect>
      </div>
      <p class="mt-3">
        <button
          type="button"
          class="btn btn-primary btn-labeled btn-labeled-start rounded-pill"
          :disabled="!selectedCity"
          @click="setCity"
        >
          <span class="btn-labeled-icon bg-black bg-opacity-20 rounded-pill">
            <i class="ph ph-path"></i>
          </span>
          Selecionar Cidade
        </button>
      </p>
      <p>
        <strong class="text-danger">Atenção!</strong> para concretagens em áreas
        rurais, favor entrar em contato com nossos especialistas.
      </p>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'ChooseCity',

  data() {
    return {
      isLoadingCities: true,
      selectedCity: null,
      cities: [],
    }
  },
  components: { Multiselect, LoadingData },
  methods: {
    async getCities() {
      try {
        this.isLoadingCities = true
        this.cities = await this.$store.dispatch('getCities')
        this.isLoadingCities = false
        this.$emit('setHeightContent')
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    setCity() {
      this.$emit('setCity', this.selectedCity)
    },
    goBackToPreviousStep() {
      this.$emit('goBackToPreviousStep')
    },
  },
  mounted() {
    this.getCities()
  },
}
</script>
<style scoped></style>
