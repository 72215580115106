import { createRouter, createWebHistory } from 'vue-router'
import ProfileEnum from '@/enum/profileEnum'
import store from '../store'
import PATHS from './routesMap'

import Welcome from '../views/home/Home.vue'

const routes = [
  {
    name: 'Welcome',
    path: PATHS.WELCOME,
    component: Welcome,
  },
  {
    name: 'BudgetDetails',
    path: PATHS.BUDGET_DETAILS,
    component: () =>
      import(
        /* webpackChunkName: "budget" */ '../views/budget/BudgetDetails.vue'
      ),
  },
  {
    name: 'Unauthorized',
    path: PATHS.UNAUTHORIZED,
    component: () =>
      import(
        /* webpackChunkName: "unauthorized" */ '../views/unauthorized/Unauthorized.vue'
      ),
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue'
      ),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from) => {})

router.afterEach(async (to, from) => {})

export default router
